import React from "react"
import { navigate } from "gatsby"

import Image from "../../components/image/image"
import ContactForm from "../../components/forms/contact-form/contact-form"

export default function FullPage({ backgroundColor, episodeId, children }) {

    return (
        <>
            <div id={episodeId} className="full-page" style={{ background: backgroundColor }}>
                { children }
            </div>
        </>
    )
}
