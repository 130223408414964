export const initialState = {
  type: "coaching",
  name: "",
  email: "",
  newsletter: false,
  consent: false,
  title: "",
  experience: "",
  linkedin: "",
  why: "",
  brandName: "",
  brandUrl: "",
  budget: "",
  product: "",
  target: "",
  kpi: "",
  targetNumber: "",
}

export const years = ["None", "1-2 years", "3-5 years", "5+ years"]

export const budget = [
  "0 to 499$ per month",
  "500 to 2,499$ per month",
  "2,500 to 9,999$ per month",
  "+10,000$ per month",
]

export const target = [
  "I don't know",
  "Budget",
  "Cost of sales",
  "Cost of profit",
  "ROAS",
  "CPA",
  "Branding KPI's",
  "Other",
]
