import React from "react"

import ContactForm from "../../../fetch/contactForm"
import Image from "../../image/image"
import ConnectionFailed from "../connection-failed"

export default function Confirm(props) {
  const { page, state, SwitchPage, setOpenForm, href } = props
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  const submitForm = (e) => {
    e.preventDefault()
    setLoading(true)
    const object = state;
    object.page = href
    object.pageName = "Coaching Form"
    ContactForm(object).then(() => {
      SwitchPage(e, "thank-you")
      setLoading(false)
    }).catch(() => {
      setLoading(false)
      setError(true)
    })
  }

  return (
    <>
    <div className={page === "confirm" ? "confirm show" : "confirm hide"}>
      <div className={page === "confirm" ? "text show" : "text hide"}>
        <b>Overview</b>
        <br />
        The application is for me to gather enough information to evaluate if
        you and your business is a good fit for the Google Ads coaching program.
        I want to make sure you get value for your investment and make sure I
        feel confident that I can bring value to your table.
        <br />
        <br />
        <b>Next step</b>
        <br />
        I'll contact you through the email you provided whenever I've evaluated your application. If you get approved, we'll set up a video-call to meet and talk in person. Before this meeting, I'll ask kindly for view rights to your Google Ads account so that I can make an initial audit of your current setup.
        <br />
        <br />
        <b>Operational work</b>
        <br />
        During the coaching program, you are doing all the account's operational work while being coached and supervised. Therefore, you or someone else in your organization must have the time to follow-up on our action plan's tasks for the week.
        <br />
        <br />
        <b>Price</b>
        <br />
        If you get approved as a trainee, and you accept the coaching collaboration after our video-call, then you can choose between two different pricing tiers
        <br />
        <ul>
          <li>
            <b>3 months</b> — 2,000$ per month
          </li>
          <li>
            <b>6 months</b> — 1,500$ per month
          </li>
        </ul>
        <i>
        There is a 14 days guarantee period. If you feel unsure and want to end the collaboration after the first 14 days, then you're free to do so — No question asked.
        </i>
        <br />
      </div>
      <button
        type="button"
        id={loading ? "loading" : ""}
        className={
          page === "confirm" ? "submit-button show" : "submit-button hide"
        }
        onClick={e => submitForm(e, "thank-you")}
      >
        {loading ? <div className="loading-icon"><Image src="loading.svg" /></div> : "Send application"}
      </button>
      <button
        type="button"
        className={
          page === "confirm"
            ? "submit-button show back"
            : "submit-button hide back"
        }
        onClick={e => SwitchPage(e, "brand")}
      >
        Back
      </button>
    </div>
    <ConnectionFailed error={error} setOpenForm={setOpenForm} />
    </>
  )
}
