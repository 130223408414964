import React from "react"
import { navigate } from "gatsby"

import Image from "../../components/image/image"
import ContactForm from "../../components/forms/contact-form/contact-form"

export default function Start({ toggleForm }) {
  const [openForm, setOpenForm] = React.useState(false)
  const goTo = url => {
    navigate(url)
    window.scrollTo({ top: 0, behavior: "smooth" })
    // Fix bug with cursor disappearing when changing page
    const cursor = document.querySelector(".cursor")
    cursor.classList.remove("hide")
  }

  const toggleContactForm = type => {
    setTimeout(() => {
      setOpenForm(type)
    }, 0)
  }

  return (
    <>
      <ContactForm openForm={openForm} setOpenForm={setOpenForm} href={"https://www.johanneseklund.com/"} />
      <div id="start-page">
        <Image
          rel="preload"
          className="full-width-banner"
          src="profile-background.jpg"
          title="Profile Image"
          alt="Profile Image"
        />
        <div className="start-header">
          <div className="text-line">
            <h1>
              Ecommerce
              <br />
              Digital marketing
              <br />
              <span>Consultant</span>
            </h1>
          </div>
          <div className="text-line">
            <h3>
              Hi, My name is Johannes, and I thrive on assisting businesses to
              become successful online. As a former{" "}
              <b>
                Senior at one of the World's Best Performance Marketing
                Agencies
              </b>
              , I have developed a deep understanding of transforming and
              scaling online businesses.
            </h3>
            <h3>
              By leveraging intelligent digital roadmaps, collecting,
              analyzing, and using actionable data, we can scale and grow your
              digital business while holding the expenses down.
            </h3>
            <h3>
              With Full-Funnel strategies and profound technical knowledge, I
              help my clients accelerate their digital maturity using Paid
              Marketing, Tracking, Automation, Integrations, and SEO.
            </h3>
          </div>
          <div id="spacer"></div>
        </div>
        <div className="cta-wrapper">
          <div className="primary-cta" onClick={toggleContactForm}>
            Connect with me!
          </div>
          {/* <div className="primary-cta" onClick={() => toggleForm(true)}>
          Join coaching-program
        </div>
        <div
          className="secondary-cta"
          onClick={toggleContactForm}
        >
          Contact
        </div> */}
        </div>
        <div className="read-more">
          <Image
            src="mouse_white.svg"
            alt="Scroll down to read more about my digital marketing services"
            title="Scroll down to read more about my digital marketing services"
            width="50"
            height="50"
          />
          Scroll to
            <br />
          Clients
        </div>
      </div>
    </>
  )
}
