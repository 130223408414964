import React, { useState } from "react"

import { budget, target } from "./data"

export default function Brand(props) {
  const { page, state, stateDispatch, SwitchPage } = props
  const [openBudget, setOpenBudget] = useState(false)
  const [openTarget, setOpenTarget] = useState(false)

  const SwitchMultiSelect = (country, type) => {
    stateDispatch({ type: "field", value: country, field: type })
    setOpenBudget(false)
    setOpenTarget(false)
  }

  return (
    <form onSubmit={e => SwitchPage(e, "confirm")}>
      <div className={page === "brand" ? "text-field show" : "text-field hide"}>
        <p>What's the name of your brand?</p>
        <input
          type="text"
          placeholder="Amazon"
          value={state.brandName}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.value,
              field: "brandName",
            })
          }
          required
        />
      </div>
      <div className={page === "brand" ? "text-field show" : "text-field hide"}>
        <p>Add a link to your website</p>
        <input
          type="url"
          placeholder="https://www.amazon.com/"
          value={state.brandUrl}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.value,
              field: "brandUrl",
            })
          }
          required
        />
        <p className="require">You must include http:// or https://</p>
      </div>
      <div
        className={
          page === "brand" ? "radio-select show" : "radio-select hide"
        }
      >
        <p>What's your monthly budget for Google Ads?</p>
        <select
          type="radio"
          value={state.budget}
          placeholder="0-499$ per month"
          
        >
          <option value={state.experbudgetence}>{state.budget}</option>
        </select>
        <div
          className={
            state.budget ? "multi-select" : "multi-select placeholder"
          }
          onClick={() => setOpenBudget(true)}
        >
          {state.budget ? state.budget : "Click to select"}
        </div>
      </div>
      {openBudget ? (
        <div className="open-multi-select">
        <div className="multi-select-title">What's your monthly budget for Google Ads?</div>
          <div className="multi-select-list">
            <ul>
              {budget.map(num => (
                <li
                  key={num}
                  onClick={() => SwitchMultiSelect(num, "budget")}
                >
                  {num}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
      <div className={page === "brand" ? "text-field show" : "text-field hide"}>
        <p>What are you selling?</p>
        <input
          type="text"
          placeholder="T-shirts"
          value={state.product}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.value,
              field: "product",
            })
          }
          required
        />
      </div>
      <div
        className={
          page === "brand" ? "radio-select show" : "radio-select hide"
        }
      >
        <p>How do you meassure your marketing?</p>
        <select
          type="radio"
          value={state.target}
          placeholder="Cost of sales"
        >
          <option value={state.target}>{state.target}</option>
        </select>
        <div
          className={
            state.target ? "multi-select" : "multi-select placeholder"
          }
          onClick={() => setOpenTarget(true)}
        >
          {state.target ? state.target : "Click to select"}
        </div>
      </div>
      {openTarget ? (
        <div className="open-multi-select">
          <div className="multi-select-title">How do you meassure your marketing?</div>
          <div className="multi-select-list">
            <ul>
              {target.map(num => (
                <li
                  key={num}
                  onClick={() => SwitchMultiSelect(num, "target")}
                >
                  {num}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
      {state.target === "Other" ? <div className={state.target === "Other" && page === "brand" ? "text-field show" : "text-field hide"}>
        <p>Add your marketing KPI here</p>
        <input
          type="text"
          placeholder="Your KPI here"
          value={state.kpi}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.value,
              field: "kpi",
            })
          }
        />
      </div> : null}
      <button
        className={
          page === "brand" ? "submit-button show" : "submit-button hide"
        }
        type="submit"
      >
        Next
      </button>
      <button
        type="button"
        className={
          page === "brand"
            ? "submit-button show back"
            : "submit-button hide back"
        }
        onClick={e => SwitchPage(e, "yourself")}
      >
        Back
      </button>
    </form>
  )
}
