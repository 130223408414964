import React, { useState } from "react"

// CSS
import "../scss/index.scss"
import "../scss/responsive.scss"

// SEO
import SEO from "../seo/main"

// Component
import StartView from "../components/index/start-new"
import CoachingForm from "../components/forms/coaching-form/coaching-form"
import FullPage from "../components/index/full-page"

import Image from "../components/image/image"

const quoteArray = [
  {
    text: "Having worked together on several large Google Ads customers, I've been impressed with Johannes drive to continuously innovate to solve customer challenges.",
    author: "Andrea Tardell, Business Development Lead",
    image: "google-logo.png",
  },
  {
    text: "Being a client and having Johannes in control over your digital marketing, you simply couldn't be in better hands.",
    author: "Frida Grönning, Digital Specialist",
    image: "precis-digital-logo-black.png",
  },
  {
    text: "Johannes is a senior person within a very broad area which makes him truly competent among many areas.",
    author: "Indika Lacis, Digital Marketing Specialist",
    image: "kappahl-logo-black.png",
  },
  {
    text: "Johannes was an incredible important member of our team and did great things both internally and externally.",
    author: "Sofie Simonsson, Team Lead",
    image: "precis-digital-logo-black.png",
  },
  {
    text: "The last two years I have had the pleasure of leaning against Johannes and his knowledge in digital media.",
    author: "Hilda Sjörs, E-commerce Manager",
    image: "kappahl-logo-black.png",
  },
  {
    text: "I was continuously struck by his drive to understand data, and through that deliver great results for the clients in his team.",
    author: "Albin Yngve, Data Advisory Lead",
    image: "precis-digital-logo-black.png",
  },
  {
    text: "I won't think twice about having Johannes on my team. He brings so much value that you will see benefits in a very short time. ",
    author: "Andres Cuaran, Digital Marketing Consultant",
    image: "",
  },
]

const clientArray = [
  {
    name: "Trickle",
    description: "Marketing Agency",
    image: "trickle-logo.png",
    url: "https://wearetrickle.com/",
  },
  {
    name: "Elvine",
    description: "Urban Clothing",
    image: "elvine-logo.png",
    url: "https://www.elvine.com/",
  },
  {
    name: "TMRW",
    description: "Architectual Vizualization Studio",
    image: "tmrw-logo.png",
    url: "https://www.tmrw.inc/",
  },
  {
    name: "Voice Machine",
    description: "Voice Over SaaS",
    image: "voicemachine-logo.jpeg",
    url: "https://www.wickedfamily.com",
  },
  {
    name: "Wicked Family",
    description: "Motocross Brand",
    image: "wicked-family-logo.png",
    url: "https://www.wickedfamily.com",
  },
  {
    name: "Gunry",
    description: "Body Care Products",
    image: "gunry-logo.png",
    url: "https://www.gunry.se/sv/",
  },
  {
    name: "Topz",
    description: "Beauty & Hygiene",
    image: "topz-logo.png",
    url: "https://www.topz.se/sv/",
  },
  {
    name: "Rybrinks",
    description: "Company Group",
    image: "rybrinks-logo.png",
    url: "https://www.rybrinks.se/",
  },
]

export default function Profile() {
  const [openForm, setOpenForm] = useState(false)
  const [quote, setQuote] = useState(quoteArray[0])
  const [fade, setFade] = useState("fade-in")
  const [index, setIndex] = useState(0)
  const [clientHover, setClientHover] = useState(false)
  const toggleForm = type => {
    setTimeout(() => {
      setOpenForm(type)
    }, 0)
  }

  const toggleClient = type => {
    const clientObject = type > -1 ? clientArray[type] : false
    setClientHover(clientObject)
  }

  const fadeMagic = () => {
    setFade("fade-in")
    setTimeout(() => {
      setFade("fade-out")
      setTimeout(() => {
        let newIndex = index + 1
        if (!quoteArray[newIndex]) {
          newIndex = 0
        }
        setIndex(newIndex)
        setQuote(quoteArray[newIndex])
      }, 500)
    }, 4500)
  }

  React.useEffect(() => {
    fadeMagic()
  }, [index])

  return (
    <>
      <SEO />
      <CoachingForm
        openForm={openForm}
        setOpenForm={setOpenForm}
        href={"https://www.johanneseklund.com/"}
      />
      <div id="home">
        <StartView toggleForm={toggleForm} />
        <FullPage episodeId={"client"} backgroundColor={"black"}>
          <div className="start-header">
            <div className="client-wrapper">
              {clientArray.map((obj, i) => {
                if (i < 4)
                  return (
                    <div
                      className="client"
                      onMouseEnter={() => toggleClient(i)}
                      onMouseLeave={() => toggleClient(false)}
                    >
                      <Image src={obj.image} alt={"Logo of " + obj.name} />
                    </div>
                  )
              })}
            </div>
            <div className="heading">
              <h3>{!clientHover ? "Clients" : clientHover.name}</h3>
              <h4>
                {!clientHover
                  ? "Which I have the honor to help"
                  : clientHover.description}
              </h4>
            </div>
            <div className="client-wrapper">
              {clientArray.map((obj, i) => {
                if (i >= 4)
                  return (
                    <div
                      className="client"
                      onMouseEnter={() => toggleClient(i)}
                      onMouseLeave={() => toggleClient(false)}
                    >
                      <Image src={obj.image} alt={"Logo of " + obj.name} />
                    </div>
                  )
              })}
            </div>
          </div>
          <div className="read-more white">
            <Image
              src="mouse_white.svg"
              alt="Scroll down to read more about my digital marketing services"
              title="Scroll down to read more about my digital marketing services"
              width="50"
              height="50"
            />
            Scroll to
            <br />
            References
          </div>
        </FullPage>
        <FullPage episodeId={"quote"} backgroundColor={"#DFF3E3"}>
          <div className="start-header">
            <div className="heading">
              <h5>What does kind people say about me?</h5>
            </div>
            <div className={"quote " + fade}>
              <h2>
                <i>{quote.text}</i>
              </h2>
              <h4 className="author">
                {quote.author}
                <Image src={quote.image} />
              </h4>
            </div>
          </div>
          <div className="read-more">
            <Image
              src="mouse_black.svg"
              alt="Scroll down to read more about my digital marketing services"
              title="Scroll down to read more about my digital marketing services"
              width="50"
              height="50"
            />
            Scroll to
            <br />
            Services
          </div>
        </FullPage>
        <FullPage episodeId={"work"} backgroundColor={"#ABC4A1"}>
          <div className="start-header">
            <div className="heading">
              <h5>What I can help out with</h5>
            </div>
            <div className="heading">
              <h3>Project Management / Strategy</h3>
              <h3>Paid Media / SEO / Tracking / Analytics</h3>
              <h3>Automation / Integration / Audits</h3>
            </div>
          </div>
          <div className="read-more">
            <Image
              src="mouse_black.svg"
              alt="Scroll down to read more about my digital marketing services"
              title="Scroll down to read more about my digital marketing services"
              width="50"
              height="50"
            />
            Scroll to
            <br />
            Client Cases
          </div>
        </FullPage>
        <FullPage episodeId={"google-partner"} backgroundColor={"#A39171"}>
          <div className="start-header">
            <div className="heading grey">
              <h3>I'm a Google Partner</h3>
              {/* <h6>Over $10,000 in Monthly Spend</h6>
              <h6>Proven Knowledge in Google Products</h6>
              <h6>Above 70% in Optimization Score</h6> */}
              <a
                href="https://www.google.com/partners/agency?id=3893235446"
                target="_blank"
              >
                <img
                  src="https://www.gstatic.com/partners/badge/images/2022/PartnerBadgeClickable.svg"
                  alt="Johannes Eklund Google Partner Badge"
                />
              </a>
            </div>
          </div>
          <div className="read-more">
            <Image
              src="mouse_black.svg"
              alt="Scroll down to read more about my digital marketing services"
              title="Scroll down to read more about my digital marketing services"
              width="50"
              height="50"
            />
            Scroll to
            <br />
            Client Cases
          </div>
        </FullPage>
        <FullPage episodeId={"cases"} backgroundColor={"black"}>
          <div className="start-header">
            <div className="heading">
              <h3>Cases</h3>
              <h4>Things we've have done in the past</h4>
            </div>
          </div>
          <div className="read-more white">
            <Image
              src="mouse_white.svg"
              alt="Scroll down to read more about my digital marketing services"
              title="Scroll down to read more about my digital marketing services"
              width="50"
              height="50"
            />
            Scroll to
            <br />
            Case: Gunry
          </div>
        </FullPage>
        <FullPage episodeId={"specific-case"} backgroundColor={"#fffded"}>
          <div className="wrapper">
            <div className="text-line">
              <h2>
                Case: <span>Gunry</span>
                <br />
              </h2>
              <h5>
                Responsibility: <span>Ecommerce Project Manager</span>
              </h5>
              <h3>
                Gunry has a powerful brand in Sweden, but they had only been
                selling B2B through resellers. They wanted to try out selling
                B2C by setting up their e-commerce, and then they reached out to
                me.
              </h3>
              <h3>
                I led the project from the start to fully functional e-commerce.
                From the beginning, choosing an e-com system that would fit
                Gunry's current inventory management, negotiated contracts with
                payment gateways, web development agencies, and integration
                companies. To hold expenses down and gain more in-house control
                I choose to designed and set up the store myself based on the
                web development agency's best practices. This process did make
                the initial cost for a developing the ecommerce down with around
                $11,700 to $6,700.
              </h3>
              <h3>
                Since then, everything is seamlessly connected to Gunry's order
                management. The order stock is growing. We have focused on
                organic growth by leveraging Gunry's strong brand awareness and
                authority while talking to our visitors through paid media.
              </h3>
              <h3>
                You can check out the website I personally built by{" "}
                <a href="https://www.gunry.se" target="_blank" rel="noopener">
                  going to Gunry's Website.
                </a>
              </h3>
            </div>
            <div id="spacer"></div>
          </div>
          <div className="read-more">
            <Image
              src="mouse_black.svg"
              alt="Scroll down to read more about my digital marketing services"
              title="Scroll down to read more about my digital marketing services"
              width="50"
              height="50"
            />
            Scroll to Case:
            <br />
            Wicked Family
          </div>
        </FullPage>
        <FullPage episodeId={"specific-case"} backgroundColor={"#FFFBBD"}>
          <div className="wrapper">
            <div className="text-line">
              <h2>
                Case: <span>Wicked Family</span>
                <br />
              </h2>
              <h5>
                Responsibility: <span>Performance Marketing</span>
              </h5>
              <h3>
                Wicked Family got in contact with me when the iOS 14.5 situation
                hit. They wanted to try out Google Shopping, and outsource the
                Paid Ads and SEO responsibility.
              </h3>
              <h3>Since then I have implemented, and setup</h3>
              <h3>
                <ul>
                  <li>
                    Feed management plugin, and connected it to Google Shopping.
                    The feed quality it super high, and we have tied information
                    to all important product attributes possible.
                  </li>
                  <li>
                    Cookie Saver service that enables us to set a cookie
                    duration longer then 7 days, even after ITP and iOS 14.5.
                  </li>
                  <li>
                    Review plugin that feed correct data for microdata and
                    review feed data that make it possible to add star reviews
                    in both Google Shopping and Google Organic.
                  </li>
                  <li>
                    Structured Data plugin that enables us more features and
                    better performance in Google Organic by adding necessary
                    information.
                  </li>
                  <li>
                    Fraud Click service that blocks out bots and fraud clicks
                    from clicking on Paid Ads and wasting money.
                  </li>
                  <li>
                    Moved over control of Enhanced Ecommerce tracking by setting
                    up tracking through Google Tag Manager instead of 3d party
                    plugin.
                  </li>
                </ul>
              </h3>
            </div>
            <div id="spacer"></div>
          </div>
          <div className="read-more">
            <Image
              src="mouse_black.svg"
              alt="Scroll down to read more about my digital marketing services"
              title="Scroll down to read more about my digital marketing services"
              width="50"
              height="50"
            />
            Scroll to
            <br />
            Contact
          </div>
        </FullPage>
        <FullPage episodeId={"specific-case"} backgroundColor={"#EEEBD3"}>
          <div className="wrapper">
            <div className="text-line">
              <h2>
                Case: <span>Voicemachine</span>
                <br />
              </h2>
              <h5>
                Responsibility: <span>Performance Marketing</span>
              </h5>
              <h3>
                To better measure a funnel and, at the same time, be able to
                feed better data to the Advertisement channels, we implemented
                Enhanced Ecommerce in Google Analytics 4 but made it lead
                generation focused.
              </h3>
              <h3>Why implement Enhanced Ecommerce for Lead Generation</h3>
              <h3>
                <ul>
                  <li>
                    Focus our tracking, reporting, and mindset for visualizing
                    the user journey.
                  </li>
                  <li>
                    Collect revenue in Google Analytics 4 for better insights,
                    profit comparison, and channel optimization.
                  </li>
                  <li>
                    Sending User-ID, which unlocks many cool new features and
                    possibilities.
                  </li>
                  <li>
                    Ensure we're tracking key events and can better map out
                    information to enhance the data.
                  </li>
                  <li>
                    To be able to start making remarketing using dynamic
                    messaging with business feeds.
                  </li>
                </ul>
              </h3>
            </div>
            <div id="spacer"></div>
          </div>
          <div className="read-more">
            <Image
              src="mouse_black.svg"
              alt="Scroll down to read more about my digital marketing services"
              title="Scroll down to read more about my digital marketing services"
              width="50"
              height="50"
            />
            Scroll to
            <br />
            Contact
          </div>
        </FullPage>
      </div>
    </>
  )
}
