import React, { useState } from "react"

import { years } from "./data"

export default function Yourself(props) {
  const { page, state, stateDispatch, SwitchPage } = props
  const [openYears, setOpenYears] = useState(false)

  const SwitchMultiSelect = (country, type) => {
    stateDispatch({ type: "field", value: country, field: type })
    setOpenYears(false)
  }

  return (
    <form
      onSubmit={e =>
        SwitchPage(
          e,
          "brand"
        )
      }
    >
      <div
        className={page === "yourself" ? "text-field show" : "text-field hide"}
      >
        <p>What's your professional title?</p>
        <input
          type="text"
          placeholder="Head of marketing"
          value={state.title}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.value,
              field: "title",
            })
          }
          required
        />
      </div>
      <div
        className={
          page === "yourself" ? "radio-select show" : "radio-select hide"
        }
      >
        <p>How many years experience in Google Ads?</p>
        <select type="radio" value={state.experience} placeholder="1-2 years" required>
          <option value={state.experience}>{state.experience}</option>
        </select>
        <div className={state.experience ? "multi-select" : "multi-select placeholder"} onClick={() => setOpenYears(true)}>
          {state.experience ? state.experience : "Click to select"}
        </div>
      </div>
      {openYears ? (
        <div className="open-multi-select">
          <div className="multi-select-title">How many years experience in Google Ads?</div>
          <div className="multi-select-list">
            <ul>
              {years.map(year => (
                <li
                  key={year}
                  onClick={() => SwitchMultiSelect(year, "experience")}
                >
                  {year}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
      <div
        className={page === "yourself" ? "text-field show" : "text-field hide"}
      >
        <p>LinkedIn Profile</p>
        <input
          type="url"
          pattern="^http(s)?:\/\/(www\.)?linkedin\.com\/in\/.*$"
          placeholder="https://www.linkedin.com/in/johannes-eklund/"
          value={state.linkedin}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.value,
              field: "linkedin",
            })
          }
          required={false}
        />
      </div>
      <div
        className={page === "yourself" ? "text-field show" : "text-field hide"}
      >
        <p>Why do you want to be mentored?</p>
        <textarea
          type="text"
          placeholder="Write a sentence or two"
          value={state.why}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.value,
              field: "why",
            })
          }
          required
        />
      </div>
      <button
        className={
          page === "yourself" ? "submit-button show" : "submit-button hide"
        }
        type="submit"
      >
        Next
      </button>
      <button
        type="button"
        className={
          page === "yourself"
            ? "submit-button show back"
            : "submit-button hide back"
        }
        onClick={e => SwitchPage(e, "start")}
      >
        Back
      </button>
    </form>
  )
}
